"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardCreateTemplateModeEnum = exports.IntegrationEnum = exports.TemplateModeEnum = void 0;
var TemplateModeEnum;
(function (TemplateModeEnum) {
    TemplateModeEnum["AUTOMATIC"] = "automatic";
    TemplateModeEnum["MANUAL"] = "manual";
})(TemplateModeEnum = exports.TemplateModeEnum || (exports.TemplateModeEnum = {}));
var IntegrationEnum;
(function (IntegrationEnum) {
    IntegrationEnum["JAVASCRIPT"] = "Javascript";
    IntegrationEnum["REACT"] = "React";
    IntegrationEnum["ANGULAR"] = "Angular";
    IntegrationEnum["BUBBLE"] = "Bubble";
})(IntegrationEnum = exports.IntegrationEnum || (exports.IntegrationEnum = {}));
var OnboardCreateTemplateModeEnum;
(function (OnboardCreateTemplateModeEnum) {
    OnboardCreateTemplateModeEnum["SAMPLE_COLUMN"] = "SAMPLE_COLUMN";
    OnboardCreateTemplateModeEnum["FILE_MAP_COLUMN"] = "FILE_MAP_COLUMN";
})(OnboardCreateTemplateModeEnum = exports.OnboardCreateTemplateModeEnum || (exports.OnboardCreateTemplateModeEnum = {}));
